import { axiosInstance } from 'api/instance';
import {
  PaginatedOrders,
  Model,
  Order,
  OrdersFilterParams,
  PaginatedTags,
  Tag,
  DockerImage,
  ModelApi,
  NamedModel,
  ModelApiName,
} from 'api/Exec/types';
import urls from 'api/Exec/urls';
import { PrsPdfMetadata } from 'app/common_components/PdfFormData/types';

import { AxiosResponse } from 'axios';

export const getOrdersRequest = (
  page = 1,
  pageSize = 10,
  filter: OrdersFilterParams,
  abortController?: AbortController,
): Promise<AxiosResponse<PaginatedOrders>> => {
  return axiosInstance.get<PaginatedOrders>(urls.ordersListUrl(page, pageSize, filter), {
    signal: abortController?.signal,
  });
};

export const createOrderRequest = (
  sourceFileId: string,
  modelName: string,
  tagsIds: string[],
  pdfMetadata: PrsPdfMetadata,
): Promise<AxiosResponse<Order[]>> => {
  return axiosInstance.post<Order[]>(urls.ordersUrl, { sourceFileId, modelName, tagsIds, pdfMetadata });
};

export const updateOrderRequest = (orderId: string, tagsIds: string[]): Promise<AxiosResponse<Order>> => {
  return axiosInstance.patch<Order>(urls.ordersItemUrl(orderId), { tagsIds });
};

export const getModelsRequest = ({ signal }: AbortController): Promise<AxiosResponse<Model[]>> => {
  return axiosInstance.get<Model[]>(urls.modelsUrl, { signal });
};

export const createModelRequest = (imageTag: string, modelApiName: ModelApiName, modelName?: string): Promise<AxiosResponse<Model>> => {
  return axiosInstance.post<Model>(urls.modelsUrl, { imageTag, modelApiName, modelName });
};

export const getModelsImagesRequest = ({ signal }: AbortController): Promise<AxiosResponse<DockerImage[]>> => {
  return axiosInstance.get<DockerImage[]>(urls.modelsImagesUrl, { signal });
};

export const getModelsApiRequest = ({ signal }: AbortController): Promise<AxiosResponse<ModelApi[]>> => {
  return axiosInstance.get<ModelApi[]>(urls.modelsApiUrl, { signal });
};

export const getNamedModelsRequest = (hideDeprecated = false, { signal }: AbortController): Promise<AxiosResponse<NamedModel[]>> => {
  return axiosInstance.get<NamedModel[]>(urls.namedModelsListUrl(hideDeprecated), { signal });
};

export const addNamedModelRequest = (modelId: string, modelName: string): Promise<AxiosResponse<NamedModel>> => {
  return axiosInstance.post<NamedModel>(urls.namedModelsUrl, { modelId, modelName });
};

export const updateNamedModelRequest = (namedModelId: string, modelId: string, modelName: string): Promise<AxiosResponse<NamedModel>> => {
  return axiosInstance.put(urls.namedModelsItemUrl(namedModelId), { modelId, modelName });
};

export const cancelOrderRequest = (orderId: string): Promise<AxiosResponse> => {
  return axiosInstance.post(urls.cancelOrderUrl, { orderId });
};

export const getTagsRequest = (
  page = 1,
  pageSize = 10,
  name = '',
  abortController?: AbortController,
): Promise<AxiosResponse<PaginatedTags>> => {
  return axiosInstance.get<PaginatedTags>(urls.tagsListUrl(page, pageSize, name), { signal: abortController?.signal });
};

export const createTagRequest = (name: string): Promise<AxiosResponse<Tag>> => {
  return axiosInstance.post<Tag>(urls.tagsUrl, { name });
};

export const deleteTagRequest = (tagId: string): Promise<AxiosResponse> => {
  return axiosInstance.delete(urls.tagsItemUrl(tagId));
};

export const updateTagRequest = (tagId: string, name: string): Promise<AxiosResponse<Tag>> => {
  return axiosInstance.put<Tag>(urls.tagsItemUrl(tagId), { name });
};
