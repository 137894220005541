import { PaginatedData } from 'app/types/PaginatedData';

export type PaginatedOrders = PaginatedData<Order>;

export interface Order {
  id: string;
  status: Status;
  srcFileId: string;
  srcFileShortPath: string;
  srcFileName: string;
  startedAt: string;
  amountOfSamples: number;
  executionTime: number | null;
  submittedBy: string;
  model: string;
  type: string;
  orgName: string;
  tags: Tag[];
  resultTypes?: {
    type: string;
    label: string;
  }[];
  canRequestPdf: boolean;
  virtual: boolean;
}

export enum Status {
  EMPTY = '',
  REGISTERED = 'Registered',
  PREPARING = 'Preparing',
  SUBMITTED = 'Submitted',
  RUNNING = 'Running',
  MODEL_COMPLETED = 'Model completed',
  COMPLETED = 'Completed',
  FAILED = 'Failed',
  CANCELED = 'Canceled',
  CANCELING = 'Canceling',
  FINALIZING = 'Finalizing',
  MAKING_REPORT = 'Making report',
  COLLECT_REPORT_RESULT = 'Collecting report results',
  REPORTS_FAILED = 'Reports failed',
}

export interface Model {
  id: string;
  modelApiName: ModelApiName;
  assignedName: string;
  modelImageTag: string;
  modelGitCommit: string;
  description: string | null;
  enabled: boolean;
}

export interface DockerImage {
  imageTag: string;
}

export type ModelApiName = 'vader' | 'skywalker' | 'hutt' | 'palpatine' | 'mysterio';

export interface ModelApi {
  value: ModelApiName;
  label: string;
}

export interface NamedModel {
  id: string;
  modelName: string;
  modelApiName: ModelApiName;
  modelImageTag: string;
  deprecated: boolean;
}

export interface Tag {
  id: string;
  name: string;
  orgId: string;
}

export type PaginatedTags = PaginatedData<Tag>;

export interface OrdersFilterParams {
  search: string;
  orgs: string[];
  status: Status;
  tags: string[];
  model: string;
  modelApi: string;
  minDate: string;
  maxDate: string;
  type: string;
}
