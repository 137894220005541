import { ModelApiName } from 'api/Exec/types';
import { useAppSelector } from 'store/hooks';
import { selectOrgModels } from 'store/orgs/orgsSlice';

import { Collapse, DatePicker, Form, Input } from 'antd';
import React, { useMemo, useState } from 'react';

const renderItems = (isLoading: boolean, modelApiName: ModelApiName) => {
  if (modelApiName === 'mysterio') {
    return (
      <>
        <Form.Item name={[ 'pdfData', 'patientId' ]} label="Patient id">
          <Input placeholder="Enter patient id" disabled={isLoading} />
        </Form.Item>
        <Form.Item name={[ 'pdfData', 'patientName' ]} label="Patient name">
          <Input placeholder="Enter patient name" disabled={isLoading} />
        </Form.Item>
        <Form.Item name={[ 'pdfData', 'dob' ]} label="Date of birth">
          <DatePicker format="YYYY/MM/DD" placeholder="Select date of birth" disabled={isLoading} style={{ width: '100%' }} />
        </Form.Item>
        <Form.Item name={[ 'pdfData', 'sex' ]} label="Sex">
          <Input placeholder="Enter sex" disabled={isLoading} />
        </Form.Item>
        <Form.Item name={[ 'pdfData', 'accession' ]} label="Accession No">
          <Input placeholder="Enter accession no" disabled={isLoading} />
        </Form.Item>
        <Form.Item name={[ 'pdfData', 'collected' ]} label="Sample collected">
          <DatePicker format="YYYY/MM/DD" placeholder="Select collected date" disabled={isLoading} style={{ width: '100%' }} />
        </Form.Item>
        <Form.Item name={[ 'pdfData', 'received' ]} label="Sample received">
          <DatePicker format="YYYY/MM/DD" placeholder="Select received date" disabled={isLoading} style={{ width: '100%' }} />
        </Form.Item>
        <Form.Item name={[ 'pdfData', 'specimenType' ]} label="Specimen type">
          <Input placeholder="Enter specimen type" disabled={isLoading} />
        </Form.Item>
        <Form.Item name={[ 'pdfData', 'providerName' ]} label="Provider name">
          <Input placeholder="Enter provider name" disabled={isLoading} />
        </Form.Item>
        <Form.Item name={[ 'pdfData', 'referringFacility' ]} label="Referring facility">
          <Input placeholder="Enter referring facility" disabled={isLoading} />
        </Form.Item>
        <Form.Item name={[ 'pdfData', 'address' ]} label="Address">
          <Input placeholder="Enter address" disabled={isLoading} />
        </Form.Item>
      </>
    );
  }
};

interface Props {
  isLoading: boolean;
  modelName: string;
}

const PdfFormData: React.FC<Props> = ({ isLoading, modelName }) => {
  const [ modelApiName, setModelApiName ] = useState<ModelApiName | null>(null);
  const orgModels = useAppSelector(selectOrgModels);

  const containPdfData: boolean = useMemo((): boolean => {
    const model = orgModels.filter(orgModel => orgModel.modelName === modelName)[0];

    if (model) {
      const { modelApiName } = model;
      setModelApiName(modelApiName);

      return modelApiName === 'mysterio';
    }

    return false;
  }, [ modelName, orgModels ]);

  return modelApiName && containPdfData ? (
    <Collapse
      defaultActiveKey={[ 'pdfData' ]}
      items={[
        {
          key: 'pdfData',
          label: 'PDF data',
          children: renderItems(isLoading, modelApiName),
        },
      ]}
      style={{ marginBottom: 24 }}
    />
  ) : null;
};

export default PdfFormData;
